import { FC } from 'react';

import { HorizontalRule } from '@custom-components/HorizontalRule';
import { Placeholder } from '@sitecore/common';
import { Box, PageGrid, Text } from '@sparky';

const OxxioFooter: FC = () => (
  <Box backgroundColor="backgroundDark">
    <Text color="textInverted">
      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
          <Box paddingY="10">
            <Placeholder name="jss-footer-navigation" />
          </Box>
        </PageGrid.Item>
      </PageGrid>
      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
          <HorizontalRule backgroundColor="backgroundBrand"></HorizontalRule>
        </PageGrid.Item>
      </PageGrid>

      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1', md: '1/7' }}>
          <Box paddingY="10">
            <Placeholder name="jss-subfooter-left" />
          </Box>
        </PageGrid.Item>
        <PageGrid.Item gridColumn={{ initial: '1/-1', md: '7/13' }}>
          <Box paddingY="10">
            <Placeholder name="jss-subfooter-right" />
          </Box>
        </PageGrid.Item>
      </PageGrid>
    </Text>
  </Box>
);

export default OxxioFooter;
