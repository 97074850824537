import { Box } from '@sparky';
import { styled } from '@sparky/stitches';
import { BoxProps } from '@sparky/types';

const WrappedBox = styled(Box, {
  height: '1px',
});

export const HorizontalRule = ({ backgroundColor }: { backgroundColor: BoxProps['backgroundColor'] }) => {
  return <WrappedBox backgroundColor={backgroundColor}> </WrappedBox>;
};
